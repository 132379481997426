import React from 'react';
import './NotFound.css'; // استيراد ملف CSS للتنسيق

const NotFound = () => {
  const redirectToHome = () => {
    window.location.href = '/'; // توجيه المستخدم إلى الصفحة الرئيسية
  };

  return (
    <div className="not-found-container"> {/* تطبيق الكلاس للتنسيق */}
      <div>
        <h1 className="not-found-header">404 - الصفحة غير موجودة</h1> {/* تطبيق الكلاس للتنسيق */}
        <p className="not-found-text">عذرًا، الصفحة التي تبحث عنها غير موجودة.</p> {/* تطبيق الكلاس للتنسيق */}
        <button className="not-found-button" onClick={redirectToHome}>الرجوع إلى الصفحة الرئيسية</button> {/* إضافة زر الرجوع مع الكلاس للتنسيق */}
      </div>
    </div>
  );
}

export default NotFound;
