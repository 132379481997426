import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import Navigation from './Pages/1-Header/Navigation';
import Footer from './Pages/3-Footer/Footer';
import NotFound from './Pages/2-Hero/NotFound';
import ScrollToTop from './Pages/ScrollToTop ';

const Home = React.lazy(() => import('./Pages/2-Hero/Home'));
const About = React.lazy(() => import('./Pages/2-Hero/About'));
const Conners = React.lazy(() => import('./Pages/2-Hero/Conners'));
const Gars = React.lazy(() => import('./Pages/2-Hero/Gars'));
const DownloadPDF = React.lazy(() => import('./Pages/2-Hero/DownloadPDF'));
const Psychological = React.lazy(() => import('./Pages/2-Hero/Psychological'));
const ContactUs = React.lazy(() => import('./Pages/2-Hero/ContactUs'));
const Privacy = React.lazy(() => import('./Pages/2-Hero/Privacy'));
const AgeCalculator = React.lazy(() => import('./Pages/2-Hero/AgeCalculator'));
const TermsOfUsePage = React.lazy(() => import('./Pages/2-Hero/TermsOfUsePage'));
const TermsAndConditions = React.lazy(() => import('./Pages/2-Hero/TermsAndConditions'));
const GrantConsentButton = React.lazy(() => import('./Pages/2-Hero/GrantConsentButton'));
const Roolen = React.lazy(() => import('./Pages/2-Hero/Roolen'));
const Questionnaire = React.lazy(() => import('./Pages/2-Hero/Questionnaire'));
const PersonalityTest = React.lazy(() => import('./Pages/2-Hero/PersonalityTest'));
const Blog = React.lazy(() => import('./Pages/2-Hero/Blog'));
const Depression = React.lazy(() => import('./Pages/Article/Depression'));
const MySonsADHDStory = React.lazy(() => import('./Pages/Article/MySonsADHDStory'));
const AutismTreatmentGuide = React.lazy(() => import('./Pages/Article/AutismTreatmentGuide'));
const ObsessiveDisorder = React.lazy(() => import('./Pages/Article/ObsessiveDisorder'));
const SocialPhobia = React.lazy(() => import('./Pages/Article/SocialPhobia'));
const MentalHealth = React.lazy(() => import('./Pages/Article/MentalHealth'));
const BipolarDisorder = React.lazy(() => import('./Pages/Article/BipolarDisorder'));
const ParentingGuide = React.lazy(() => import('./Pages/Article/ParentingGuide'));



const DownloadPage = React.lazy(() => import('./Pages/2-Hero/DownloadPage'));
const SliderPage = React.lazy(() => import('./Pages/Component/SliderPage'));
const SliderArticle = React.lazy(() => import('./Pages/Component/SliderArticle'));

const App = () => {
  return (
    <div>
      <Navigation />
      <ScrollToTop />
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/depression" element={<Depression />} />
          <Route path="/mySonsADHDStory" element={<MySonsADHDStory />} />
          <Route path="/AutismTreatmentGuide" element={<AutismTreatmentGuide />} />
          <Route path="/ObsessiveDisorder" element={<ObsessiveDisorder />} />
          <Route path="/SocialPhobia" element={<SocialPhobia />} />
          <Route path="/MentalHealth" element={<MentalHealth />} />
          <Route path="/BipolarDisorder" element={<BipolarDisorder />} />

          <Route path="/downloadPage" element={<DownloadPage />} />
          <Route path="/about" element={<About />} />
          <Route path="/conners" element={<Conners />} />
          <Route path="/Gars" element={<Gars />} />
          <Route path="/AgeCalculator" element={<AgeCalculator />} />
          <Route path="/DownloadPDF" element={<DownloadPDF />} />
          <Route path="/Psychological" element={<Psychological />} />
          <Route path="/contactUs" element={<ContactUs />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/TermsOfUsePage" element={<TermsOfUsePage />} />
          <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
          <Route path="/Roolen" element={<Roolen />} />
          <Route path="/Questionnaire" element={<Questionnaire />} />
          <Route path="/PersonalityTest" element={<PersonalityTest />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/SliderPage" element={<SliderPage />} />
          <Route path="/SliderArticle" element={<SliderArticle />} />
          <Route path="/ParentingGuide" element={<ParentingGuide />} />

        </Routes>
      </Suspense>
      <GrantConsentButton />
      <Footer />
    </div>
  );
};

export default App;
