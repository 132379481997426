import { useRef } from "react";
import { Link } from 'react-router-dom';
import './Navigation.css';
import logo from '../Images/logo192.png';
import { FaBars, FaTimes } from "react-icons/fa";


const Navigation = () => {
    const navRef = useRef();

    const closeNavbar = () => {
        if (navRef.current.classList.contains("responsive_nav")) {
            navRef.current.classList.remove("responsive_nav");
        }
    }

    const toggleNavbar = () => {
        navRef.current.classList.toggle("responsive_nav");
    }

    return (
        <header>
            <Link to="/" onClick={closeNavbar}><img src={logo} alt='logo' loading='lazy'/></Link>
            <nav ref={navRef}>
                <Link to="/" onClick={closeNavbar}>الصفحة الرئيسية</Link>
                <Link to="/Blog" onClick={closeNavbar}>المدونة</Link>
                <Link to="/Psychological" onClick={closeNavbar}>مقاييس نفسية</Link>
                <Link to="/DownloadPDF" onClick={closeNavbar}>تحميل PDF</Link>


                <button className="nav-btn nav-close-btn" onClick={toggleNavbar}>
                    <FaTimes/>
                </button>
            </nav>
            <button className="nav-btn" onClick={toggleNavbar}>
                <FaBars/>
            </button>
        </header>
    );
}

export default Navigation;
