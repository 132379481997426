import React from 'react';
import { createRoot } from 'react-dom/client';  // تحديث الاستيراد هنا
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import './index.css';

import 'slick-carousel/slick/slick.css';    // تحديث الاستيراد معرض الصور
import 'slick-carousel/slick/slick-theme.css';   // تحديث الاستيراد معرض الصور


const root = document.getElementById('root');
const rootElement = createRoot(root);

rootElement.render(
  <Router>
    <App />
  </Router>
);
